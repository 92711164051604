<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card" style="z-index: 2;">
                            <!-- ==========主要內容區========== -->
                            <br/>
                            <v-row>
                                <v-col class="px-10">
                                    <v-text-field label="事業單位名稱" 
                                        v-model="orgName"
                                        outlined 
                                        hide-details>
                                    </v-text-field>
                                </v-col>
                                <v-col class="px-10">
                                    <v-text-field label="統一編號" 
                                        v-model="orgIdno"
                                        outlined 
                                        hide-details>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            
                            <v-row>
                                <v-col class="px-10">
                                    <v-btn class="mr-4 white--text" color="#37906C" @click="toCreateOrg()">
                                        <v-icon >mdi-note-plus</v-icon>新增事業單位
                                    </v-btn>
                                </v-col>
                            </v-row>
                            
                            <v-row>
                                <v-col>
                                    <div class="v-data-footer">
                                        <div class="v-data-footer__select">每頁列數:<v-select
                                        v-model="listPageSize"
                                        :items="pageSizeList"
                                        dense
                                        @change="nextPage(1, listPageSize)"
                                        ></v-select></div>
                                        <div class="v-data-footer__pagination">
                                        共 {{pageInfo.totalCount}} 筆資料，全部 {{pageInfo.maxPage}} 頁，目前第{{pageInfo.currentPage}}頁
                                        <v-icon class="mx-4" @click="nextPage(pageInfo.currentPage-1, listPageSize)" :disabled="pageInfo.currentPage == 1">mdi-chevron-left</v-icon>
                                        <v-icon class="mx-4" @click="nextPage(pageInfo.currentPage+1, listPageSize)" :disabled="pageInfo.currentPage >= pageInfo.maxPage">mdi-chevron-right</v-icon>
                                        </div>
                                    </div>
                                    <v-data-table
                                    :headers="headers"
                                    :items="datalist"
                                    :items-per-page="listPageSize"
                                    height="800px"
                                    hide-default-footer
                                    disable-sort
                                    class="mx-12"> 
                                        <template v-slot:[`item.orgdel`]={item}>
                                            <v-icon @click.stop="toDel(item)">mdi-delete-forever</v-icon>
                                        </template>
                                        <template v-slot:[`item.orgedit`]={item}>
                                            <v-icon @click.stop="toEdit(item)" >mdi-square-edit-outline</v-icon>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <!-- ==========主要內容區========== -->
                        </div>

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import loadingMask from '@/components/public/loadingMask.vue'
import {getOrgnizationList, delOrgnizationData} from "@/api/hyirecoApi";

export default {
  name: 'orgnizationList',
  mixins: [commonMixin],
  created(){
        this.authValidate();
        this.auth = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getTokenWithSession(),
            }
        };
  },
  destroyed() {
        clearInterval(this.interval);
  },
  mounted(){
      //console.log("loginAuth" + JSON.stringify(this.loginAuth));
      this.userName = this.loginAuth.memberName;
      this.getDataList();
  },
  computed: {
    headers() {
      return [
          {
            text: '事業單位名稱',
            align: 'left',
            value: 'name',
            class: "subtitle-2 font-weight-black",
          },
          { text: '統一編號', value: 'idn', class: "subtitle-2 font-weight-black",width:120,},
          { text: '狀態', value: 'statusCH', class: "subtitle-2 font-weight-black",width:100,},
          { text: '維護', value: 'orgedit', class: "subtitle-2 font-weight-black",width:80,},
          { text: '刪除', value: 'orgdel', class: "subtitle-2 font-weight-black",width:80, },
      ]
    },
  },
  methods: {
    getDataList: function() { 
        var vm = this;
        vm.isLoading = true;
        
        getOrgnizationList(vm.queryRequest, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //console.log("response = " + JSON.stringify(responseData));
                
                if (responseData.statusCode === "1") {
                    vm.datalist = responseData.queryPager.resultList;
                    //console.log("datalist = " + JSON.stringify(vm.datalist));

                    vm.datalist = vm.datalist.map(function(item){
                        item.statusCH = item.status === "Y" ? "啟用" : "停用";
                        return item;
                    });

                    vm.pageInfo.maxPage = responseData.queryPager.maxPage;
                    vm.pageInfo.currentPage = responseData.queryPager.currentPage;
                    vm.pageInfo.totalCount = responseData.queryPager.totalCount;
                } else {
                    vm.datalist = [];
                    if (responseData.statusCode !== "2") vm.$swal(responseData.message);
                }
            }
            vm.isLoading = false;
        });
       
    },
    toEdit: function(value) {
        let vm = this;
        vm.$router.push({
            path: "/orgnization/orgnizationEdit/" + value.orgId
        });
    },
    toDel: function(value) {
        let vm = this;
        vm.$swal({
            title: "<font face='Microsoft JhengHei'>請確認</font>",
            html: "<font face='Microsoft JhengHei'>確認後將立即刪除 " + value.name + "</font>",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "<font face='Microsoft JhengHei' color='#fff'>確認</font>",
            cancelButtonText: "<font face='Microsoft JhengHei' color='#fff'>取消</font>",
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false
        }).then(function(result) {
            if (result.isConfirmed) {
                vm.isLoading = true;
                var orgId = value.orgId;

                delOrgnizationData(orgId, vm.auth).then(function(response) {
                    if ("200" == response.status) { 
                        var responseData = response.data;
                        //console.log("response = " + JSON.stringify(responseData));
                        vm.$swal(responseData.message);

                        if (responseData.statusCode === "1") {
                            // vm.datalist = vm.datalist.filter(function(item){
                            //     return item.orgId !== value.orgId;
                            // });
                            vm.getDataList();
                        }
                    }
                    vm.isLoading = false;
                });    
            }
        });
    },
    toCreateOrg: function() {
        this.$router.push({
            path: "/orgnization/orgnizationEdit/init"
        });
    },
    keyupDelay: function() {
        //用於延遲查詢，避免value改變時連續送出request
        let vm = this;
        clearTimeout(vm.timer);
        
        vm.timer = setTimeout(function() {
            vm.getDataList();
        }, 800);
    },
    nextPage: function(currentPage, pageSize) {
      //console.log("next page = " + value);
      let vm = this;
      vm.queryRequest.queryPager.pageSize = pageSize;
      vm.queryRequest.queryPager.currentPage = currentPage;
      vm.getDataList();
    },
  },
  watch: {
      orgName: function(value){
          this.queryRequest.queryObject.name = value;
          this.keyupDelay();
      },
      orgIdno: function(value){
          this.queryRequest.queryObject.idn = value;
          this.keyupDelay();
      },
  },
  data: () => ({
    userName: "",
    datalist: [],
    orgName: "",
    orgIdno: "",
    timer: null,
    isLoading: false,
    auth:{},
    pageInfo: {
      maxPage: 1,
      currentPage: 1,
      totalCount: 0,
    },
    listPageSize: 10,
    pageSizeList: [
      {text:"10", value:10},
      {text:"30", value:30},
      {text:"50", value:50},
    ],
    queryRequest: {
        queryObject: {
            startTrDateStr: "",
            endTrDateStr: "",
            checkStatus: [],
            organizer: [],
            trId: "",
            trType: null,
        },
        queryPager: {
            currentPage: 1,
            pageSize: 10,
        },
    },
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>

</style>
